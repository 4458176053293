import React from 'react'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'react-datepicker/dist/react-datepicker.css'
import './scss/DatepickerOverrides.scss'
import './scss/global.scss'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
// import * as Sentry from '@sentry/react'
import { initializeIcons } from '@fluentui/react'
initializeIcons()

// Sentry.init({
//   dsn: 'https://20eec9261065e68b1ee265dc2583142d@o4507635207045120.ingest.de.sentry.io/4507635215499344',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0,
//   replaysOnErrorSampleRate: 1.0,
// })

// Fix deprecated package types
const MatomoProviderFixed = MatomoProvider as (
  props: ComponentPropsWithoutRef<typeof MatomoProvider> & {
    children: ReactNode
  },
) => JSX.Element

const matomoInstance = createInstance({
  urlBase: '//analytics-schweer.de/',
  siteId: 5,
  disabled: process.env.REACT_APP_BOOKING_SYSTEM_ENV === 'development',
  heartBeat: {
    active: true,
    seconds: 10,
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MatomoProviderFixed value={matomoInstance}>
      <App />
    </MatomoProviderFixed>
  </React.StrictMode>,
)
